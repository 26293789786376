import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProfileForm = () => {
  const [username, setUsername] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAlertVisible(false);
    setLoading(true);

    try {
      const response = await axios.post("/api/open-profile", {
        profileName: `${countryCode}${username}`,
      });
      setLoading(false);

      if (response.data.success && response.data.success.msg) {
        const userName = response.data.success.name;
        const userUsername = response.data.success.username;
        setAlertMessage(response.data.success.msg);
        setAlertVisible(true);

        setTimeout(() => {
          setAlertVisible(false);
          navigate(`/welcome?name=${encodeURIComponent(userName)}&username=${encodeURIComponent(userUsername)}`);
        }, 2000);
      } else {
        setAlertMessage(response.data.error.msg);
        setAlertVisible(true);

        setTimeout(() => {
          setAlertVisible(false);
          navigate(`/not-registered`);
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      setAlertMessage(error.response?.data?.error?.msg || "An error occurred");
      setAlertVisible(true);

      setTimeout(() => {
        setAlertVisible(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = (canvas.width = window.innerWidth);
    const height = (canvas.height = window.innerHeight);
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("");
    const fontSize = 16;
    const columns = Math.floor(width / fontSize);
    const drops = Array(columns).fill(1);

    const draw = () => {
      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = "#0F0";
      ctx.font = `${fontSize}px monospace`;

      for (let i = 0; i < drops.length; i++) {
        const text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        if (drops[i] * fontSize > height && Math.random() > 0.975) {
          drops[i] = 0;
        }

        drops[i]++;
      }
    };

    const interval = setInterval(draw, 33);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-black text-hacker-green">
      <canvas ref={canvasRef} className="absolute inset-0 w-full h-full"></canvas>
      <div className="relative z-10 p-6 md:p-8 lg:p-12 bg-gray-900 bg-opacity-75 rounded-lg shadow-lg max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
        <form id="profileForm" onSubmit={handleSubmit} className="space-y-6">
          <h2 className="text-center text-2xl sm:text-3xl md:text-4xl font-extrabold text-hacker-green animate-pulse">
            Hey 👾 Hacker{" "}
          </h2>
          <div className="space-y-4">
            <label htmlFor="username" className="block text-hacker-green text-sm font-bold">
              Enter Your 0xProfile Username/Email/Phone Number:
            </label>
            <div className="flex items-center">
              <span className="inline-block bg-gray-700 text-hacker-green px-3 py-2 rounded-l-md">
                0x
              </span>
              <select
                id="countryCode"
                name="countryCode"
                className="bg-gray-700 text-hacker-green px-2 py-2 h-full border-l border-gray-600 rounded-l-md focus:outline-none"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
              >
                <option value="">Select Code</option>
                <option value="+91">+91 (IN)</option>
                <option value="+1">+1 (US/CA)</option>
                <option value="+44">+44 (UK)</option>
                <option value="+61">+61 (AU)</option>
                {/* Add more options as needed */}
              </select>
              <input
                type="text"
                id="username"
                name="username"
                className="shadow appearance-none border rounded-r-md w-full py-2 px-3 text-hacker-green leading-tight focus:outline-none focus:shadow-outline bg-gray-700 h-10"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <p className="text-xs text-gray-500">
              *Please select your country code when entering your phone number.
            </p>
          </div>
          {alertVisible && (
            <div id="alert" className="text-center text-sm italic text-red-500">
              {alertMessage}
            </div>
          )}
          {loading && (
            <div id="loading" className="text-center text-sm italic text-blue-500">
              Loading...
            </div>
          )}
          <div className="flex items-center justify-center">
            <button
              id="submitBtn"
              className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm;
